import '../assets/fonts/loader.css'
import '../css/main.css'
import '../css/site.css'

import { GatsbyBrowser } from 'gatsby'
import React from 'react'

/* global window, document */
const scrollTo = (id: string) => () => {
  const el = document.querySelector(id) as HTMLElement
  if (el) return window.scrollTo(0, el.offsetTop)
  return false
}

const gatsbyBrowser: GatsbyBrowser = {
  onRouteUpdate: ({ location: { hash } }) => {
    if (hash) {
      window.setTimeout(scrollTo(hash), 500)
    }
  }
}

const BaseApp = props => {
  const { element } = props
  return element
}

const wrapRootElement: GatsbyBrowser['wrapRootElement'] = props => {
  const { element } = props

  return React.createElement(BaseApp, { element })
}

export { gatsbyBrowser, wrapRootElement }

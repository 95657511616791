module.exports = [{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-google-gtag@4.20.0_gatsby@4.25.9_react-dom@16.13.1_react@16.13.1/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-JQ233Q5BC1","UA-126524857-4"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-facebook-pixel@1.0.8/node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"646555736193038"},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-google-tagmanager@2.4.0_gatsby@2.25.1/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5XTFCMC","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-nprogress@2.1.5_gatsby@2.25.1/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"rebeccapurple","showSpinner":false,"trickle":true,"minimum":0.08},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-canonical-urls@2.1.6_gatsby@2.25.1/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://sunlight.dentist","stripQueryString":true},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-theme-apollo@3.0.3_@apollo+client@3.1.3_gatsby@2.25.1/node_modules/gatsby-theme-apollo/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-intl@0.3.3_gatsby@2.25.1_react@16.13.1/node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/home/clinic-official-site/packages/sunlight/src/locales","languages":["zh-TW"],"defaultLanguage":"zh-TW","redirect":false},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-anchor-links@1.1.1/node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-100},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-use-query-params@1.0.1_gatsby@2.25.1_react-dom@16.8.6_react@16.13.1_use-query-params@1.1.6/node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-next-seo@1.10.0_gatsby@4.25.9_react-helmet-async@1.2.3_typescript@3.9.5/node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]

import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import fetch from 'isomorphic-fetch'

const uri = `${process.env.API_URL}/graphql`

const uploadLink = createUploadLink({
  credentials: 'include',
  headers: {
    'Access-Control-Allow-Origin': `${process.env.SITE_URL}`,
    'Access-Control-Allow-Credentials': 'true'
  },
  uri,
  fetch
})

const cache = new InMemoryCache()
const link = ApolloLink.from([uploadLink])

const client = new ApolloClient({
  cache,
  link
})

export default client

// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-case-tsx": () => import("./../../../src/pages/case.tsx" /* webpackChunkName: "component---src-pages-case-tsx" */),
  "component---src-pages-clinic-guting-tsx": () => import("./../../../src/pages/clinic/guting.tsx" /* webpackChunkName: "component---src-pages-clinic-guting-tsx" */),
  "component---src-pages-clinic-hsinchu-tsx": () => import("./../../../src/pages/clinic/hsinchu.tsx" /* webpackChunkName: "component---src-pages-clinic-hsinchu-tsx" */),
  "component---src-pages-clinic-index-tsx": () => import("./../../../src/pages/clinic/index.tsx" /* webpackChunkName: "component---src-pages-clinic-index-tsx" */),
  "component---src-pages-clinic-taichung-tsx": () => import("./../../../src/pages/clinic/taichung.tsx" /* webpackChunkName: "component---src-pages-clinic-taichung-tsx" */),
  "component---src-pages-clinic-tainan-tsx": () => import("./../../../src/pages/clinic/tainan.tsx" /* webpackChunkName: "component---src-pages-clinic-tainan-tsx" */),
  "component---src-pages-clinic-taipei-tsx": () => import("./../../../src/pages/clinic/taipei.tsx" /* webpackChunkName: "component---src-pages-clinic-taipei-tsx" */),
  "component---src-pages-dashboard-tsx": () => import("./../../../src/pages/dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-service-allergy-tsx": () => import("./../../../src/pages/service/allergy.tsx" /* webpackChunkName: "component---src-pages-service-allergy-tsx" */),
  "component---src-pages-service-bps-complete-denture-tsx": () => import("./../../../src/pages/service/bpsCompleteDenture.tsx" /* webpackChunkName: "component---src-pages-service-bps-complete-denture-tsx" */),
  "component---src-pages-service-child-ortho-tsx": () => import("./../../../src/pages/service/childOrtho.tsx" /* webpackChunkName: "component---src-pages-service-child-ortho-tsx" */),
  "component---src-pages-service-child-tsx": () => import("./../../../src/pages/service/child.tsx" /* webpackChunkName: "component---src-pages-service-child-tsx" */),
  "component---src-pages-service-dental-implant-tsx": () => import("./../../../src/pages/service/dentalImplant.tsx" /* webpackChunkName: "component---src-pages-service-dental-implant-tsx" */),
  "component---src-pages-service-dental-treatment-tsx": () => import("./../../../src/pages/service/dentalTreatment.tsx" /* webpackChunkName: "component---src-pages-service-dental-treatment-tsx" */),
  "component---src-pages-service-digital-tsx": () => import("./../../../src/pages/service/digital.tsx" /* webpackChunkName: "component---src-pages-service-digital-tsx" */),
  "component---src-pages-service-orthodontics-tsx": () => import("./../../../src/pages/service/orthodontics.tsx" /* webpackChunkName: "component---src-pages-service-orthodontics-tsx" */),
  "component---src-pages-service-periodontal-tsx": () => import("./../../../src/pages/service/periodontal.tsx" /* webpackChunkName: "component---src-pages-service-periodontal-tsx" */),
  "component---src-pages-service-restorative-tsx": () => import("./../../../src/pages/service/restorative.tsx" /* webpackChunkName: "component---src-pages-service-restorative-tsx" */),
  "component---src-pages-service-retainer-tsx": () => import("./../../../src/pages/service/retainer.tsx" /* webpackChunkName: "component---src-pages-service-retainer-tsx" */),
  "component---src-pages-service-snore-tsx": () => import("./../../../src/pages/service/snore.tsx" /* webpackChunkName: "component---src-pages-service-snore-tsx" */),
  "component---src-pages-service-teeth-whitening-tsx": () => import("./../../../src/pages/service/teethWhitening.tsx" /* webpackChunkName: "component---src-pages-service-teeth-whitening-tsx" */),
  "component---src-pages-service-veneers-tsx": () => import("./../../../src/pages/service/veneers.tsx" /* webpackChunkName: "component---src-pages-service-veneers-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */)
}

